<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-data-grid-v2
  AUTHOR : hmlee
  DATE : 2024-11-04
  DESCRIPTION : 그리드 컴포넌트 ver2
                그리드/컬럼 옵션 값 최소화
-->
<template>
  <div>
    <div>
      <DxDataGrid
        id="dxDataGrid"
        class="grid-box"
        :ref="dataGridConfig.refName"
        :width="dataGridConfig.width"
        :height="dataGridConfig.height"
        :allow-column-reordering="dataGridConfig.allowColumnReordering"
        :allow-column-resizing="dataGridConfig.allowColumnResizing"
        :cache-enabled="dataGridConfig.cacheEnabled"
        :column-auto-width="dataGridConfig.columnAutoWidth"
        :column-min-width="dataGridConfig.columnMinWidth"
        :column-resizing-mode="dataGridConfig.columnResizingMode"
        :data-source="dataGridConfig.dataSource"
        :focused-row-enabled="dataGridConfig.focusedRowEnabled"
        :hover-state-enabled="dataGridConfig.hoverStateEnabled"
        :key-expr="dataGridConfig.keyExpr"
        :no-data-text="noDataText()"
        :row-alternation-enabled="dataGridConfig.rowAlternationEnabled"
        :show-borders="dataGridConfig.showBorders"
        :show-column-headers="dataGridConfig.showColumnHeaders"
        :show-column-lines="dataGridConfig.showColumnLines"
        :show-row-lines="dataGridConfig.showRowLines"
        :word-wrap-enabled="dataGridConfig.wordWrapEnabled"
        :selected-row-keys="dataGridConfig.selectedRowKeys"
        @adaptive-detail-row-preparing="handleAdaptiveDetailRowPreparing"
        @cell-click="handleCellClick"
        @cell-dbl-click="handleCellDblClick"
        @cell-hover-changed="handleCellHoverChanged"
        @cell-prepared="handleCellPrepared"
        @content-ready="handleContentReady"
        @context-menu-preparing="handleContextMenuPreparing"
        @data-error-occurred="handleDataErrorOccurred"
        @disposing="handleDisposing"
        @edit-canceled="handleEditCanceled"
        @edit-canceling="handleEditCanceling"
        @editing-start="handleEditingStart"
        @editor-prepared="handleEditorPrepared"
        @editor-preparing="handleEditorPreparing"
        @exporting="handleExportingCheck"
        @focused-cell-changed="handleFocusedCellChanged"
        @focused-cell-changing="handleFocusedCellChanging"
        @focused-row-changed="handleFocusedRowChanged"
        @focused-row-changing="handleFocusedRowChanging"
        @initialized="handleInitialized"
        @init-new-row="handleInitNewRow"
        @key-down="handleKeyDown"
        @option-changed="handleOptionChanged"
        @row-click="handleRowClick"
        @row-collapsed="handleRowCollapsed"
        @row-collapsing="handleRowCollapsing"
        @row-dbl-click="handleRowDblClick"
        @row-expanded="handleRowExpanded"
        @row-expanding="handleRowExpanding"
        @row-inserted="handleRowInserted"
        @row-inserting="handleRowInserting"
        @row-prepared="handleRowPrepared"
        @row-removed="handleRowRemoved"
        @row-removing="handleRowRemoving"
        @row-updated="handleRowUpdated"
        @row-updating="handleRowUpdating"
        @row-validating="handleRowValidating"
        @saved="handleSaved"
        @saving="handleSaving"
        @selection-changed="handleSelectionChanged"
        @toolbar-preparing="handleToolbarPreparing"
      >
        <!-- 항목 출력 여부 관련 설정 -->
        <DxColumnChooser
          v-if="dataGridConfig.columnChooser"
          :enabled="dataGridConfig.columnChooser.enabled"
        />

        <!-- 수정모드 -->
        <DxEditing
          :allow-adding="dataGridConfig.editing.allowAdding"
          :allow-updating="dataGridConfig.editing.allowUpdating"
          :allow-deleting="dataGridConfig.editing.allowDeleting"
          :confirm-delete="dataGridConfig.editing.confirmDelete"
          :edit-column-name="dataGridConfig.editing.editColumnName"
          :edit-row-key="dataGridConfig.editing.editRowKey"
          :mode="dataGridConfig.editing.mode"
          :refresh-mode="dataGridConfig.editing.refreshMode"
          :select-text-on-edit-start="dataGridConfig.editing.selectTextOnEditStart"
          :start-edit-action="dataGridConfig.editing.startEditAction"
          :new-row-position="dataGridConfig.editing.newRowPosition"
          :texts="dataGridConfig.editing.texts"
          :use-icons="dataGridConfig.editing.useIcons"
        />

        <!-- 엑셀 다운로드 설정 -->
        <DxExport
          v-if="dataGridConfig.showActionButtons.excel"
          :enabled="dataGridConfig.export.enabled"
          :allow-export-selected-data="dataGridConfig.export.allowExportSelectedData"
          :texts="dataGridConfig.export.exportButtonText"
        />

        <!-- 필터 설정 -->
        <DxFilterRow
          :v-if="dataGridConfig.filterRow"
          :visible="dataGridConfig.filterRow.visible"
        >
        </DxFilterRow>

        <!-- 그룹핑 설정 -->
        <DxGrouping
          v-if="dataGridConfig.grouping"
          :context-menu-enabled="dataGridConfig.grouping.enabled"
          :auto-expand-all="dataGridConfig.grouping.autoExpandAll"
          :allow-collapsing="dataGridConfig.grouping.allowCollapsing"
          :expand-mode="dataGridConfig.grouping.expandMode"
        />

        <!-- 그룹핑 패널 설정 -->
        <DxGroupPanel
          v-if="dataGridConfig.groupPanel"
          :visible="dataGridConfig.groupPanel.visible"
        />

        <!-- 헤더필터 설정 -->
        <DxHeaderFilter
          :v-if="dataGridConfig.headerFilter"
          :visible="dataGridConfig.headerFilter.visible"
        />

        <!-- 로딩바 표시 유무 설정 -->
        <DxLoadPanel
          v-if="dataGridConfig.loadPanel"
          :enabled="dataGridConfig.loadPanel.enabled"
        />

        <!-- 페이저 설정 -->
        <DxPager
          :visible="dataGridConfig.page.enabled"
          :show-page-size-selector="dataGridConfig.page.showPageSizeSelector"
          :allowed-page-sizes="dataGridConfig.page.allowedPageSizes"
          :display-mode="dataGridConfig.page.displayMode"
          :show-info="dataGridConfig.page.showInfo"
          :show-navigation-buttons="dataGridConfig.page.showNavigationButtons"
          :info-text="dataGridConfig.page.infoText"
        />

        <!-- 페이징 처리 설정 -->
        <DxPaging
          :enabled="dataGridConfig.page.enabled"
          :page-size="dataGridConfig.page.pageSize"
          :page-index="dataGridConfig.page.pageIndex"
        />

        <!-- 서버사이드 설정 -->
        <DxRemoteOperations
          :filtering="dataGridConfig.remoteOperations.filtering"
          :sorting="dataGridConfig.remoteOperations.sorting"
          :grouping="dataGridConfig.remoteOperations.grouping"
          :paging="dataGridConfig.remoteOperations.paging"
        />

        <!-- 행 드래그 관련 -->
        <DxRowDragging
          v-if="dataGridConfig.dragging"
          :allow-reordering="dataGridConfig.dragging.enabled"
          :drop-feedback-mode="dataGridConfig.dragging.dropFeedbackMode"
          :show-drag-icons="dataGridConfig.dragging.showDragIcons"
          :on-reorder="onReorder"
        />

        <!-- 스크롤 설정 -->
        <DxScrolling
          v-if="dataGridConfig.scrolling"
          :column-rendering-mode="dataGridConfig.scrolling.columnRenderingMode"
          :mode="dataGridConfig.scrolling.mode"
          :row-rendering-mode="dataGridConfig.scrolling.rowRenderingMode"
          :preload-enabled="dataGridConfig.scrolling.preloadEnabled"
          :render-async="dataGridConfig.scrolling.renderAsync"
          :scroll-by-content="dataGridConfig.scrolling.scrollByContent"
          :scroll-by-thumb="dataGridConfig.scrolling.scrollByThumb"
          :show-scrollbar="dataGridConfig.scrolling.showScrollbar"
          :use-native="dataGridConfig.scrolling.useNative"
        />

        <!-- 검색 패널 설정 -->
        <DxSearchPanel
          v-if="dataGridConfig.searchPanel"
          :highlightCaseSensitive="dataGridConfig.searchPanel.highlightCaseSensitive"
          :highlightSearchText="dataGridConfig.searchPanel.highlightSearchText"
          :searchVisibleColumnsOnly="dataGridConfig.searchPanel.searchVisibleColumnsOnly"
          :text="dataGridConfig.searchPanel.text"
          :visible="dataGridConfig.searchPanel.visible"
          :placeholder="dataGridConfig.searchPanel.placeholder"
          :width="dataGridConfig.searchPanel.width"
        />

        <!-- 로우 선택 설정 -->
        <DxSelection
          v-if="dataGridConfig.selecting"
          :mode="dataGridConfig.selecting.mode"
          :show-check-boxes-mode="dataGridConfig.selecting.showCheckBoxesMode"
          :select-all-mode="dataGridConfig.selecting.selectAllMode"
          :allow-select-all="dataGridConfig.selecting.allowSelectAll"
          :deferred="dataGridConfig.selecting.deferred"
        />

        <!-- 순서 설정 -->
        <DxSorting
          v-if="dataGridConfig.sorting"
          :mode="dataGridConfig.sorting.mode"
          :show-sort-indexes="dataGridConfig.sorting.showSortIndexes"
        />

        <!-- 요약 설정 -->
        <DxSummary
          v-if="dataGridConfig.summary"
          :calculate-custom-summary="dataGridConfig.summary.calculateCustomSummary"
          :group-items="dataGridConfig.summary.groupItems"
          :total-items="dataGridConfig.summary.totalItems"
          :recalculate-while-editing="dataGridConfig.summary.recalculateWhileEditing"
          :skip-empty-values="dataGridConfig.summary.skipEmptyValues"
          :texts="dataGridConfig.summary.texts"
        />

        <!-- 항목 설정 -->
        <template v-for="(column, index) in dataGridConfig.columns">
          <template v-if="column.multiHeaderNm">
            <DxColumn :key="index" :caption="$_msgContents(column.i18n, { defaultValue: column.multiHeaderNm })">
              <DxColumn
                v-for="(column2, index2) in column.columns"
                :key="`${index}_${index2}`"
                :alignment="column2.alignment"
                :allow-editing="column2.allowEditing"
                :allow-exporting="column2.allowExporting"
                :allow-filtering="column2.allowFiltering"
                :allow-grouping="column2.allowGrouping"
                :allow-header-filtering="column2.allowHeaderFiltering"
                :allow-sorting="column2.allowSorting"
                :calculate-cell-value="column2.calculateCellValue"
                :calculate-display-value="column2.calculateDisplayCellValue"
                :calculate-sort-value="column2.calculateSortValue"
                :caption="$_msgContents(column2.i18n, { defaultValue: column2.caption })"
                :cell-template="column2.cellTemplate"
                :css-class="column2.cssClass"
                :customize-text="column2.customizeText"
                :data-field="column2.dataField"
                :data-type="column2.dataType"
                :edit-cell-template="column2.editCellTemplate"
                :editor-options="column2.editorOptions"
                :editor-type="column2.editorType"
                :filterValue="column2.filterValue"
                :fixed="column2.fixed"
                :fixed-position="column2.fixedPosition"
                :format="column2.format"
                :group-index="column2.groupIndex"
                :header-cell-template="column2.headerCellTemplate"
                :height="column2.height"
                :set-cell-value="column2.setCellValue"
                :sort-order="column2.sortOrder"
                :validation-rules="column2.validationRules"
                :visible="column2.visible"
                :width="column2.width"
                :min-width="column2.minWidth"
              >
                <!-- 헤더필터 설정 -->
                <DxHeaderFilter
                  v-if="column2.headerFilter"
                  :data-source="column2.headerFilter.dataSource"
                />

                <!-- selectBox 옵션 -->
                <DxLookup
                  v-if="column2.lookup"
                  :data-source="column2.lookup.dataSource"
                  :display-expr="column2.lookup.displayExpr"
                  :value-expr="column2.lookup.valueExpr"
                  :allow-clearing="column2.lookup.allowClearing"
                />

                <!-- 필수조건 설정 -->
                <DxRequiredRule
                  v-if="column2.requiredRule"
                  :message="column2.requiredRule.message"
                />

                <!-- 패턴 규칙 설정 -->
                <DxPatternRule
                  v-if="column2.patternRule"
                  :pattern="column2.patternRule.pattern"
                  :message="column2.patternRule.message"
                />

                <!-- 커스텀 규칙 설정 -->
                <DxCustomRule
                  v-if="column2.customRule"
                  type="custom"
                  :validationCallback="column2.customRule.callback"
                  :message="column2.customRule.message"
                />
              </DxColumn>
            </DxColumn>
          </template>
          <template v-else>
            <DxColumn
              :key="index"
              :alignment="column.alignment"
              :allow-editing="column.allowEditing"
              :allow-exporting="column.allowExporting"
              :allow-filtering="column.allowFiltering"
              :allow-grouping="column.allowGrouping"
              :allow-header-filtering="column.allowHeaderFiltering"
              :allow-sorting="column.allowSorting"
              :calculate-cell-value="column.calculateCellValue"
              :calculate-display-value="column.calculateDisplayCellValue"
              :calculate-sort-value="column.calculateSortValue"
              :caption="$_msgContents(column.i18n, { defaultValue: column.caption })"
              :cell-template="column.cellTemplate"
              :css-class="column.cssClass"
              :customize-text="column.customizeText"
              :data-field="column.dataField"
              :data-type="column.dataType"
              :edit-cell-template="column.editCellTemplate"
              :editor-options="column.editorOptions"
              :editor-type="column.editorType"
              :filterValue="column.filterValue"
              :fixed="column.fixed"
              :fixed-position="column.fixedPosition"
              :format="column.format"
              :group-index="column.groupIndex"
              :header-cell-template="column.headerCellTemplate"
              :height="column.height"
              :set-cell-value="column.setCellValue"
              :sort-order="column.sortOrder"
              :validation-rules="column.validationRules"
              :visible="column.visible"
              :width="column.width"
              :min-width="column.minWidth"
            >
              <!-- 헤더필터 설정 -->
              <DxHeaderFilter
                v-if="column.headerFilter"
                :data-source="column.headerFilter.dataSource"
              />

              <!-- selectBox 옵션 -->
              <DxLookup
                v-if="column.lookup"
                :data-source="column.lookup.dataSource"
                :display-expr="column.lookup.displayExpr"
                :value-expr="column.lookup.valueExpr"
                :allow-clearing="column.lookup.allowClearing"
              />

              <!-- 필수조건 설정 -->
              <DxRequiredRule
                v-if="column.requiredRule"
                :message="column.requiredRule.message"
              />

              <!-- 패턴 규칙 설정 -->
              <DxPatternRule
                v-if="column.patternRule"
                :pattern="column.patternRule.pattern"
                :message="column.patternRule.message"
              />

              <!-- 커스텀 규칙 설정 -->
              <DxCustomRule
                v-if="column.customRule"
                type="custom"
                :validationCallback="column.customRule.callback"
                :message="column.customRule.message"
              />
            </DxColumn>
          </template>
        </template>

        <!-- toolbar -->
        <template #totalCount>
          <div class="total-count-item">
            {{ $_msgContents('COMPONENTS.SEARCH_RESULT', { defaultValue: '검색 결과' } ) }} <span class="tet-calr1">{{ dataGridConfig.page.totalCount }}</span> 개
          </div>
        </template>
      </DxDataGrid>
    </div>

    <DxPopup
      v-model="modal.visible"
      :show-title="modal.showTitle"
      :title="modal.initData.title"
      :width="modal.initData.width"
      :height="modal.initData.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :show-close-button="modal.showCloseButton"
      :close-on-outside-click="modal.closeOnOutsideClick"
      :visible="modal.visible"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="reasonModalRef"
            v-if="modal.content.sendData"
            :is="modal.content.currentComponent"
            :modalData="modal.content.sendData"
            :isModal="modal.content.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
        :widget="modal.toolbarItem.widget"
        :toolbar="modal.toolbarItem.toolbar"
        :location="modal.toolbarItem.location"
        :options="modal.toolbarItem.closeOptions"
      />
      <DxToolbarItem
        :widget="modal.toolbarItem.widget"
        :toolbar="modal.toolbarItem.toolbar"
        :location="modal.toolbarItem.location"
        :options="modal.toolbarItem.saveOptions"
      />
    </DxPopup>
  </div>
</template>

<script>
import {isSuccess, isTrue, formatDate, setGridSingleSelection} from '@/plugins/common-lib';

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxSelection,
  DxLookup,
  DxFilterRow,
  DxPaging,
  DxPager,
  DxOperationDescriptions,
  DxRemoteOperations,
  DxHeaderFilter,
  DxScrolling,
  DxSorting,
  DxColumnChooser,
  DxLoadPanel,
  DxGrouping,
  DxGroupPanel,
  DxRequiredRule,
  DxExport,
  DxRowDragging,
  DxPatternRule,
  DxCustomRule,
  DxSearchPanel,
  DxSummary,
} from 'devextreme-vue/data-grid';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import store from '@/store';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import CustomStore from 'devextreme/data/custom_store';

export default {
  components: {
    DxSummary,
    DxToolbarItem,
    DxPopup,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSelection,
    DxLookup,
    DxFilterRow,
    DxPaging,
    DxPager,
    DxOperationDescriptions,
    DxRemoteOperations,
    DxHeaderFilter,
    DxScrolling,
    DxSorting,
    DxRequiredRule,
    DxColumnChooser,
    DxLoadPanel,
    DxGrouping,
    DxGroupPanel,
    DxExport,
    DxRowDragging,
    DxPatternRule,
    DxCustomRule,
    DxSearchPanel,
    ModalDownloadReason,
  },
  props: {
    dataGrid: {
      type: Object,
      required: true,
    },
  },
  watch: {
    dataGrid: {
      handler(newDataGrid) {
        this.setDataGridData(newDataGrid); // 그리드 데이터 설정
      },
      immediate: true,
      deep: false,
    }, // dataGrid 변경감지(렌더링 직후 바로 실행, 내부 객체 변경은 감지하지 않음)
    'dataGrid.dataSource': {
      handler(newDataSource) {
        this.dataGridConfig.dataSource = newDataSource;
      },
      deep: true,
    }, // dataGrid.dataSource 변경감지(내부 객체 변경까지 감지)
  },
  data() {
    return {
      dataGridConfig: {}, // 그리드 설정값
      gridDefaultOptions:{ //그리드 디폴트 옵션값 설정을 위해 관리
        callApi: this.dataGrid.callApi ?? 'CALL_API', //api 호출
        allowColumnReordering: false, // 컬럼 재배열 허용
        allowColumnResizing: false, // 컬럼 사이즈 허용
        columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
        columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무(true일 경우 스크롤 이동시 컬럼 너비 변경 버그 발생 현상 있음)
        columnMinWidth: 80, // 컬럼 최소 사이즈
        showBorders: false, // border 유무
        showColumnHeaders: true, // 컬럼 헤더 유무
        showColumnLines: false, // 컬럼 세로선 유무
        showRowLines: true, // 컬럼 가로선 유무
        focusedRowEnabled: false, // 포커스 행 표시 유무
        rowAlternationEnabled: false, // 짝/홀수 행 배경색 다르게 표시 유무
        hoverStateEnabled: false, // hover시 행 배경색 변경 유무
        wordWrapEnabled: false, // 텍스트 줄바꿈 유무
        cacheEnabled: false, // 캐시 사용 유무
        dataSource: [], // 그리드 데이터
        selectedRowKeys: [], //선택한(체크된) 로우 키값들
        selectedRowsData: [], //선택한(체크된) 로우 데이터
        selectedCopyData: [], //카피된 데이터
        focusedRowKey: null, //클릭한 로우 id
        focusedRowData: {}, //클릭한 로우 Data
        keyExpr: 'id', // 그리드 데이터 key값
        dataSourceDefaultSortColumn: '+id', // 해당 컬럼으로 정렬: + 오름차순 / - 내림차순
        apiActionNm: { // api 호출시 사용할 action name
          update: null, // 수정
          delete: null, // 삭제
          select: null, // 조회
          merge: null, // 등록/수정
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          select: false, // 조회
          update: true, // 추가/저장/취소 한번에 설정
          add: true, // 추가 개별 설정
          save: true, // 저장 개별 설정
          cancel: true, // 취소 개별 설정
          delete: true, // 삭제
          copy: false, // 복사
          excel: false, // 엑셀 다운로드 / true 인 경우 export 옵션 값 설정 가능
          csv: false, // csv 다운로드 /  excel: true 시 사용 가능
          customButtons: [], // 커스텀 버튼
        },
        showContextMenuItems: { // 컨텍스트 메뉴 노출 설정값
          insert: false, // 행 추가
          copy: false, // 행 복사
          rowClipboard: false, // 행 클립보드
          cellClipboard: false, // 셀 클립보드
          excel: false, // 엑셀 다운로드
        },
        export: { // 엑셀 다운로드 설정
          enabled: true, // 엑셀 다운로드 버튼 표시 여부
          title: '', // 엑셀 파일명
          allowExportSelectedData: true, // 선택한 데이터만 다운로드 허용 여부
          exportButtonText: { // 버튼 정보
            exportTo: this.$_msgContents('COMPONENTS.EXCEL_DOWNLOAD', { defaultValue: '엑셀 다운로드' }),
            exportAll: this.$_msgContents('COMPONENTS.ALL_DOWNLOAD', { defaultValue: '전체 다운로드' }),
            exportSelectedRows: this.$_msgContents('COMPONENTS.SELECTED_DATA_DOWNLOAD', { defaultValue: '선택한 데이터 다운로드'}),
          },
          cellwidth: 30, // 셀 너비
          autoFilterEnabled: false, // 자동 필터 사용 여부
        },
        dragging: { // 행 드래그 관련
          enabled: false, // 행 재배열 허용
          dropFeedbackMode: 'push', // 드래그시 피드백 모드 : ['push', 'indicate']
          showDragIcons: true, // 드래그 아이콘 표시 유무
        },
        columnChooser: { // 컬럼 Chooser 설정
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: { // 로딩바 설정
          enabled: true, // 로딩바 표시 유무
        },
        sorting: { // 정렬 설정
          mode: 'multiple', // ['single', 'multiple', 'none']
          showSortIndexes: true, // 정렬 순서 표시 유무
        },
        scrolling: { // 스크롤 설정(paging 사용시 무시됨)
          columnRenderingMode: 'standard', // 컬럼 렌더링 모드 : ['standard', 'virtual']
          mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          preloadEnabled: false, // 미리 로딩 여부 / 모드 ['virtual', 'infinite'] 일때만 사용
          renderAsync: undefined, // 비동기 렌더링 여부 [true, false, undefined]
          rowRenderingMode: 'standard', // 행 렌더링 모드 : ['standard', 'virtual']
          scrollByContent: true, // 컨텐츠 스크롤 여부
          scrollByThumb: false, // 스크롤바 스크롤 여부
          showScrollbar: 'onHover', // 스크롤바 표시 여부 : ['onHover', 'onScroll', 'always', 'never']
          useNative: false, // 네이티브 스크롤 사용 여부 ['auto', 'true', 'false']
        },
        remoteOperations: { //서버사이드 설정
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        page: { // paging/pager 처리 설정(하나로 합침) / scrolling 미사용시만 적용됨
          // paging 설정
          enabled: true,
          pageIndex: 0, //페이징 인덱스
          pageSize: 20, //페이징 관련, 페이지 기본 사이즈
          totalCount: 0, //페이지 totalCount
          allowedPageSizes: [10, 20, 30, 50, 100, 300], //페이징 관련, 허용 페이지 목록수
          pageSizeCacheFl: null, //페이지 목록수 캐시 저장 여부
          pageList: [], //페이징 관련
          // pager 설정
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          infoText: '{1} 중 {0} 페이지 ({2} 개 항목)", //페이지 정보 표시 텍스트'
        },
        filterRow: { //필터 설정
          visible: true,
        },
        headerFilter: { //헤더필터 설정
          visible: false,
        },
        editing: { // 수정모드
          allowAdding: true,
          allowUpdating: true,
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch', 'form', 'popup']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          refreshMode: 'full', //수정 후 데이터 갱신 모드 : ['full', 'reshape', 'repaint']
          newRowPosition: 'viewportTop', // 새로운 행 추가 위치 : ['first', 'last', 'pageTop', 'pageBottom', 'viewportTop', 'viewportBottom']
          confirmDelete: true, //삭제시 확인 메시지 표시 여부
          editColumnName: null, // 편집중인 열 이름 / ['cell', 'batch'] 모드인 경우에만 가능
          editRowKey: null, // 편집중인 행 키값
          useIcons: true, // 아이콘 사용 여부
          texts: null, // 텍스트 설정
        },
        selecting: { //로우 선택 설정
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          allowSelectAll: true, //헤더 체크박스 선택(전체선택) 허용 여부
          deferred: false, //행 선택 지연 여부
        },
        groupPanel: { // 그룹핑 패널 설정
          visible: false, // 그룹핑 패널 표시 여부
        },
        grouping: { // 그룹핑 설정
          enabled: false, // 그룹핑 컨텍스트 메뉴 표시 여부
          autoExpandAll: false, // 그룹핑시 자동 확장 여부
          allowCollapsing: false, // 그룹핑시 접기 허용 여부
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        searchPanel: { // 검색 패널 설정
          visible: false, // 검색 패널 표시 여부
          highlightCaseSensitive: true, // 대소문자 구분 하이라이트 유무
          highlightSearchText: true, // 검색어 하이라이트 표시 유무
          searchVisibleColumnsOnly: true, // 검색 대상 컬럼만 검색 유무
          text: '', // 검색 입력박스 텍스트
          placeholder: '', // 검색 입력박스 placeholder
          width: 240, // 검색 입력박스 넓이
        },
        summary: { // summary 설정
          calculateCustomSummary: null, // 사용자 정의 계산
          groupItems: [], // 그룹별 합계
          totalItems: [], // 전체 합계
          recalculateWhileEditing: false, //수정시 재계산 여부
          skipEmptyValues: true, // 빈값 제외 여부
          texts: { // summary 텍스트
            sum: `${ this.$_msgContents('COMPONENTS.SUM', { defaultValue: '합계' }) }: {0}`,
            avg: `${ this.$_msgContents('COMPONENTS.AVG', { defaultValue: '평균' }) }: {0}`,
            count: `${ this.$_msgContents('COMPONENTS.COUNT', { defaultValue: '개수' }) }: {0}`,
            min: `${ this.$_msgContents('COMPONENTS.MIN', { defaultValue: '최소' }) }: {0}`,
            max: `${ this.$_msgContents('COMPONENTS.MAX', { defaultValue: '최대' }) }: {0}`,
          },
        },
      },
      columnDefaultOptions: { // column 기본 옵션
        alignment: 'center', // 컬럼 정렬 : ['center', 'left', 'right']
        allowEditing: true, // 추가+수정 허용 여부
        allowAdding: true, // 추가 허용 여부
        allowUpdating: true, // 수정 허용 여부
        allowExporting: true, // 엑셀 다운로드 허용 여부
        allowFixing: true, // 고정 허용 여부
        allowGrouping: true, // 그룹핑 허용 여부
        allowHeaderFiltering: true, // 헤더 필터 허용 여부
        allowHiding: true, // 컬럼 숨김 허용 여부
        allowReordering: true, // 컬럼 재배열 허용 여부
        allowResizing: true, // 컬럼 사이즈 조절 허용 여부
        allowSearch: true, // 검색 허용 여부(searchPanel 설정시 사용 가능 / false로 설정시 allowFiltering도 false로 설정)
        allowFiltering: true, // 검색 필터 허용 여부
        allowSorting: true, // 정렬 허용 여부
        autoExpandGroup: true, // 그룹 확장 여부
        fixed: false, // 고정 컬럼 여부
        fixedPosition: undefined, // 고정 컬럼 위치 : [undefined, 'left', 'right']
        minWidth: 50, // 컬럼 최소 넓이
        sortOrder: 'none', // 정렬 순서 : ['none', 'asc', 'desc']
        visible: true, // 컬럼 표시 여부
      },
      stylingMode: 'outlined', // outlined, underlined, filled
      isClone: false, // 복사 체크
      modal: { // 모달 설정
        visible: false,
        showTitle: true,
        initData: {
          title: null,
          width: 600,
          height: 400,
        },
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: false,
        closeOnOutsideClick: false,
        //contentData: null,
        content: { // 모달 내용 설정
          sendData: null,
          currentComponent: null,
          isModal: false,
        },
        toolbarItem: { // 하단 버튼 설정
          widget: 'dxButton',
          toolbar: 'bottom',
          location: 'center',
          closeOptions: {
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
            width: '120',
            height: '40',
            onClick: () => this.isOpenModal(false),
          },
          saveOptions: {
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            onClick: () => this.onDownloadReason(),
          },
        },
      },
    };
  },
  computed: {
    /** @description: 그리드 instance 정보 가져오기 */
    getGridInstance() {
      return this.$refs[this.dataGridConfig.refName].instance;
    },
    /** @description: 그리드 row 데이터 가져오기 */
    getItems() {
      return this.getGridInstance.getDataSource()._items;
    },
    /** @description: 메뉴 타이틀 가져오기 */
    getMenuTitle() {
      return this.$store.getters.getMenuList?.find(menu => menu.id === this.$route.name)?.menuNm;
    },
  },
  methods: {
    /** @description : 그리드 데이터 설정 */
    setDataGridData(dataGrid) {
      // 그리드 안에 객체 데이터 확인하여 병합 처리(ex. paging, pager, ...)
      let mergeGridOptions = {};
      Object.keys(this.gridDefaultOptions).forEach((key) => {
        if( Array.isArray(this.gridDefaultOptions[key]) ){  //배열 체크
          mergeGridOptions = { ...mergeGridOptions, ...this.mergeGridData(key, 'array') };
        }else if( typeof this.gridDefaultOptions[key] === 'object' ) { //Object 체크
          mergeGridOptions = { ...mergeGridOptions, ...this.mergeGridData(key, 'object') };
        }
      });

      //부모에서 전달된 dataGrid 기본값과 병합
      this.dataGridConfig = {
        ...this.gridDefaultOptions,
        ...dataGrid,
        ...mergeGridOptions,
      };

      // 컬럼 기본 옵션 설정
      this.dataGridConfig.columns = this.setColumnOptions();

      // dataGrid에 visible 속성을 가진 기본옵션 객체가 존재하면 해당 속성 활성화
      this.setActiveAttr(dataGrid, 'visible');

      // dataGrid에 enabled 속성을 가진 기본옵션 객체가 존재하면 해당 속성 활성화
      this.setActiveAttr(dataGrid, 'enabled');
    },
    /** @description : 그리드 데이터 병합
     *  @param objectKey : 병합할 데이터의 키값
     *  @param dataType : 병합할 데이터의 타입(array, object)
     *  @return jsonData : 병합된 데이터
     *  */
    mergeGridData(objectKey, dataType) {
      let mergedData = {}
      if( dataType === 'array' ) {
        mergedData = [
          ...(Array.isArray(this.gridDefaultOptions[objectKey]) ? this.gridDefaultOptions[objectKey] : []),
          ...(Array.isArray(this.dataGrid[objectKey]) ? this.dataGrid[objectKey] : []),
        ];
      } else if( dataType === 'object' ){
        mergedData = {
          [objectKey]: this.deepMergeObj(
            { ...(this.gridDefaultOptions[objectKey] || {}) },
            { ...(this.dataGrid[objectKey] || {}) },
          )
        };
      }
      return mergedData;
    },
    /** @description : 중첩된 객체 병합
     *  @param target : 병합할 대상 객체
     *  @param source : 병합할 소스 객체
     *  @return target : 병합된 객체
     *  */
    deepMergeObj(target, source) {
      // 객체 체크
      const isObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

      Object.keys(source).forEach(key => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (isObject(sourceValue)) { // 객체가 중첩된 경우 재귀적으로 병합
          if (!targetValue || !isObject(targetValue)) {
            target[key] = {}; // target 값이 없으면 빈 객체로 초기화
          }
          target[key] = this.deepMergeObj({ ...targetValue }, sourceValue);
        } else { // 객체가 아니면 그대로 덮어씀
          target[key] = sourceValue;
        }
      });

      return target;
    },
    /** @description: 그리드 컬럼 옵션 설정
     *  @return {Array} - 컬럼 옵션 설정값
     * */
    setColumnOptions() {
      return this.dataGridConfig.columns.map(column => {
        if( column.requiredRule ){
          const requiredRule = { //필수 값 메시지 처리
            message : this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_msgContents(column.i18n, {defaultValue: column.caption})})
          };
          column.requiredRule = { ...column.requiredRule, ...requiredRule };
        }
        return { ...this.columnDefaultOptions, ...column };
      });
    },
    /** @description: 객체가 존재하면서 해당 속성에 false가 아니면 true를 설정(해당 속성 활성화)
     *                해당 객체가 존재하면 visible, enabled 속성에 true 설정
     *                filterRow: {} && filterRow.visible != false
     *  @param obj - 설정할 객체
     *  @param attr - 설정할 속성
     *
     * */
    setActiveAttr(obj, attr) {
      // 그리드 기본 옵션에 있는 특정 속성이 있는 키 값 조회
      const keysByAttr = this.getKeysByAttr(this.gridDefaultOptions, attr);

      Object.keys(obj).forEach(key => {
        if( keysByAttr.includes(key) && this.dataGrid[key]?.[attr] !== false ){ // 객체가 존재하면서 해당 속성에 false가 아니면 true를 설정
          this.dataGridConfig[key][attr] = true;
        }
      });
    },
    /** @description: 특정 속성이 있는 key 값을 조회
     *                visible, enabled 속성이 있는 key 값 리턴
     *  @param obj - 조회할 객체
     *  @param attr - 조회할 속성
     *  @return {Array} - 조회된 key 값
     * */
    getKeysByAttr(obj, attr) {
      return Object.keys(obj).filter(key =>
        obj[key] && typeof obj[key] === 'object' && attr in obj[key]
      );
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.visible = data;
      if (!data) {
        this.modal.content.currentComponent = null;
        this.modal.initData = {};
        //this.modal.contentData = null;
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.content.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.content.sendData = sendData;
      this.isOpenModal(true);
    },
    /**
     * @description: Grid 편집모드가 켜져있다면 true
     * @returns {boolean}
     */
    hasEditData() {
      return this.getGridInstance.hasEditData();
    },
    /** @description: 그리드 행 순서 변경 관련 이벤트 */
    onReorder(e) {
      if( !this.$listeners['reorder'] ){
        e.promise = this.processReorder(e);
      } else { // 부모 컴포넌트의 이벤트 호출
        this.$emit('reorder', e);
      }
    },
    /** @description: 그리드 행 순서 변경 관련 */
    async processReorder(e) {
      const visibleRows = e.component.getVisibleRows();

      if (this.dataGridConfig.dragging && this.dataGridConfig.dragging?.sortColumn) {
        const newOrderIndex = visibleRows[e.toIndex].data[this.dataGridConfig.dragging.sortColumn];

        const updateData = {};

        const key = this.dataGridConfig?.keyExpr || 'id';
        updateData[key] = e.itemData[key];
        updateData[this.dataGridConfig.dragging.sortColumn] = newOrderIndex - 1;

        const payload = {
          actionname: this.dataGridConfig.apiActionNm.update,
          data: updateData,
          loading: true,
        };
        const res = await store.dispatch(this.dataGridConfig.callApi, payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.refresh();
        }
      }
    },
    /** @description: 적응형 세부 행이 준비될 때 발생하는 이벤트  */
    handleAdaptiveDetailRowPreparing(e) {
      if (this.$listeners['adaptive-detail-row-preparing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('adaptive-detail-row-preparing', e);
      }
    },
    /** @description: 셀 클릭 이벤트 */
    handleCellClick(e) {
      if (this.$listeners['cell-click']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-click', e);
      }
    },
    /** @description: 셀 더블 클릭 이벤트 */
    handleCellDblClick(e) {
      if (this.$listeners['cell-dbl-click']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-dbl-click', e);
      }
    },
    /** @description: 마우스 포인터가 셀에 들어오거나 나가는 후에 실행되는 이벤트  */
    handleCellHoverChanged(e) {
      if (this.$listeners['cell-hover-changed']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-hover-changed', e);
      }
    },
    /** @description: 그리드 셀 관련 준비 이벤트 */
    handleCellPrepared(e) {
      if ( e.rowType === 'header' ) {
        e.cellElement.style.textAlign = 'center';
      } else if (e.rowType === 'data') {
        const column = this.dataGridConfig.columns.find(col => col.dataField === e.column.dataField);
        if (column) {
          if (e.row.isNewRow) { // 신규 행일 때
            e.cellElement.style.pointerEvents = column.allowAdding ? 'auto' : 'none'; // 추가 허용에 따라 활성/비활성
          } else { // 기존 행일 때
            e.cellElement.style.pointerEvents = column.allowUpdating ? 'auto' : 'none'; // 수정 허용에 따라 활성/비활성
          }
        }
      } else if ( this.$listeners['cell-prepared'] ){ // 부모 컴포넌트의 이벤트 호출
        //커스텀시 해당 페이지의 이벤트 호출
        this.$emit('cell-prepared', e);
      }

      if (e.cellElement && e.cellElement.innerHTML === '&nbsp;') {
        e.cellElement.innerHTML = '-';
      }
    },
    /** @description: 그리드 컨텐츠 준비 이벤트 */
    handleContentReady(e) {
      if ( this.$listeners['content-ready'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('content-ready', e);
      } else {
        this.dataGridConfig.page.totalCount = e.component.totalCount();

        if( this.dataGridConfig.showActionButtons.excel ) { //excel 버튼 클래스 설정
          const excelBtn = document.querySelector('.excel-btn .dx-widget');
          excelBtn.classList.add('dx-dropdownbutton', 'excel');
        }
      }
    },
    /** @description: 그리드 컨텍스트메뉴 준비 관련 이벤트 */
    handleContextMenuPreparing(e) {
      if (e.target === 'content') {
        e.items = [];
        if (this.dataGridConfig.showContextMenuItems?.insert) {
          e.items.push({
            text: this.$_msgContents('COMPONENTS.ADD_ROW', { defaultValue: '행 추가' }),
            icon: 'dx-icon dx-icon-inserttable',
            onItemClick: () => {
              this.isClone = true;
              this.dataGridConfig.selectedCopyData = '';
              this.$refs[this.dataGridConfig.refName].instance.addRow();
            },
          });
        }

        if (this.dataGridConfig.showContextMenuItems?.copy) {
          e.items.push({
            text: this.$_msgContents('COMPONENTS.COPY_ROW', { defaultValue: '행 복사' }),
            icon: 'dx-icon dx-icon-insertrowabove',
            onItemClick: () => {
              this.isClone = true;
              this.dataGridConfig.selectedCopyData = e.row.data;
              this.$refs[this.dataGridConfig.refName].instance.addRow();
            },
          });
        }

        if (this.dataGridConfig.showContextMenuItems?.rowClipboard) {
          e.items.push({
            text: this.$_msgContents('COMPONENTS.ROW_CLIPBOARD', { defaultValue: '행 클립보드' }),
            icon: 'dx-icon dx-icon-copy',
            onItemClick: () => {
              let copyText = '';
              e.row.cells.forEach((d, index) => {
                if (index === 1) copyText = d.text;
                else copyText += ',' + d.text;
              });
              this.$_copyToClipboard(copyText);
            },
          });
        }

        if (this.dataGridConfig.showContextMenuItems?.cellClipboard) {
          e.items.push({
            text: this.$_msgContents('COMPONENTS.CELL_CLIPBOARD', { defaultValue: '열 클립보드' }),
            icon: 'dx-icon dx-icon-copy',
            onItemClick: () => {
              this.$_copyToClipboard(e.targetElement.innerText);
            },
          });
        }

        if (this.dataGridConfig.showContextMenuItems?.excel) {
          e.items.push({
            text: this.$_msgContents('COMPONENTS.EXCEL_DOWNLOAD', { defaultValue: '엑셀 다운로드' }),
            icon: 'dx-icon dx-icon-xlsxfile',
            onItemClick: () => {
              this.handleExportingCheck(e);
            },
          });
        }
      }
    },
    /** @description: 데이터 처리 오류가 발생하면 실행되는 이벤트 */
    handleDataErrorOccurred(e) {
      if ( this.$listeners['data-error-occurred'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('data-error-occurred', e);
      }
    },
    /** @description: 컴포넌트가 소멸될 때 실행되는 이벤트(소멸 전 정리 작업 수행) */
    handleDisposing(e) {
      if ( this.$listeners['disposing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('disposing', e);
      }
    },
    /** @description: 편집이 완전히 취소된 후 발생하는 이벤트 */
    handleEditCanceled(e) {
      if ( this.$listeners['edit-canceled'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('edit-canceled', e);
      }
    },
    /** @description: 편집 취소가 시작되기 직전에 발생하는 이벤트 */
    handleEditCanceling(e) {
      if ( this.$listeners['edit-canceling'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('edit-canceling', e);
      }
    },
    /** @description: 편집이 시작되기 직전에 발생하는 이벤트 */
    handleEditingStart(e) {
      if ( this.$listeners['editing-start'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editing-start', e);
      }
    },
    /** @description: 편집기가 생성된 후 실행되는 함수 */
    handleEditorPrepared(e) {
      if (this.$listeners['editor-prepared']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editor-prepared', e);
      }
    },
    /** @description: 셀이 변경 준비중일때 실행되는 이벤트 */
    handleEditorPreparing(e) {
      if (this.$listeners['editor-preparing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editor-preparing', e);
      }
    },
    /** @description: 엑셀 다운로드 체크 */
    handleExportingCheck(e) {
      if ( e.selectedRowsOnly ) { //선택된 데이터 다운로드 체크
        if ( this.dataGridConfig.selectedRowsData.length === 0 ) {
          e.cancel = true;
          return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      }

      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      e = this.makeSaveHistory(e);
      if (useDownReason) {
        e.cancel = true;
        this.onOpenModal(
          'ModalDownloadReason',
          {
            title: this.$_msgContents('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
            width: this.modal.initData.width || '600',
            height: this.modal.initData.height || '400',
          },
          e,
        );
      } else {
        this.onExporting(e);
      }
    } /** @description: 엑셀 다운로드 이벤트 */,
    onExporting(e) {
      if ( this.$listeners['exporting'] ) { // 부모의 페이지의 이벤트 호출
        // 커스텀 시 파일 다운로드 이력은 직접 구현해야함. 성공 유무를 확인할 수 없음
        this.$emit('exporting', e);
      } else {
        //Grid Excel Export
        const title = this.dataGridConfig.export?.title || this.getMenuTitle;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGridConfig.export.cellwidth 값에 따라 결정(없으면 Default : 30)
        const columnsArr = [];
        this.dataGridConfig.columns.forEach(() => {
          columnsArr.push({ width: this.dataGridConfig.export.cellwidth });
        });
        worksheet.columns = columnsArr;

        const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGridConfig.export.autoFilterEnabled, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            if (e.format === 'xlsx') {
              worksheet.mergeCells(2, 1, 2, this.dataGridConfig.columns.length);
            }
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            let fileName;
            if (e.format === 'csv') {
              fileName = `${title}_${today}.csv`;
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
              });
            } else {
              fileName = `${title}_${today}.xlsx`;
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
              });
            }
            return fileName;
          })
          .then(fileName => {
            // 다운로드 이력 저장
            e.onSaveHistory(fileName);
          });
        e.cancel = true;
      }
    },
    /** @description: 셀 포커스가 이미 변경된 후에 발생하는 이벤트(포커스 이후 작업 수행) */
    handleFocusedCellChanged(e) {
      if ( this.$listeners['focused-cell-changed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-cell-changed', e);
      }
    },
    /** @description: 셀 포커스가 변경되기 직전에 발생하는 이벤트(포커스 이동을 막을 수 있음) */
    handleFocusedCellChanging(e) {
      if ( this.$listeners['focused-cell-changing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-cell-changing', e);
      }
    },
    /** @description: 포커스된 행이 변경된 후 실행되는 함수 */
    handleFocusedRowChanged(e) {
      if (this.$listeners['focused-row-changed']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-row-changed', e);
      }
    },
    /** @description: 포커스된 행이 변경되기 전에 실행되는 함수 */
    handleFocusedRowChanging(e) {
      if (this.$listeners['focused-row-changing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-row-changing', e);
      }
    },
    /** @description: 그리드 초기화 이벤트 */
    handleInitialized(e) {
      if (this.$listeners['initialized']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('initialized', e);
      }
    },
    /** @description: 그리드 새 행 초기 셋팅 이벤트
     * ex) 필드의 순서 값 등 초기 셋팅 */
    handleInitNewRow(e) {
      if ( this.$listeners['init-new-row'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('init-new-row', e);
      } else if (this.isClone) {
        e.data = Object.assign({}, this.dataGridConfig.selectedCopyData);
        delete e.data.id; // remove PK property
        this.isClone = false;
      }
    },
    /** @description: 키보드 키가 눌릴 때 트리거되는 이벤트 */
    handleKeyDown(e) {
      if (this.$listeners['key-down']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('key-down', e);
      }
    },
    /** @description: 옵션 변경시 이벤트 발생(단, 초기 인입시에도 호출됨)
     * @param e : 이벤트
     * */
    handleOptionChanged(e) {
      if ( this.$listeners['option-changed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('option-changed', e);
      } else {
        if (e.fullName === 'paging.pageSize') {
          //paging pageSize 변경시
          //페이지 목록수 옵션 캐시 저장 여부 true일 경우
          const pageSize = e.value;

          //store에 페이징의 페이지 사이즈 이력 저장
          if (pageSize > 10) {
            this.dataGridConfig.page.pageSize = pageSize;
            //store에 페이징의 페이지 사이즈 이력 저장
            this.$_setPageSizePagingHists(pageSize);
          } else {
            //pageSize = 10 이면 이력에서 삭제플래그로 이력 삭제
            this.$_setPageSizePagingHists(pageSize, true);
          }
        } else if (e.fullName === 'paging.pageIndex') {
          //paging pageIndex 변경시
          const pageIndex = e.value;
          //store에 페이징의 페이지 인덱스 이력 저장
          if (pageIndex > 0) {
            this.dataGridConfig.page.pageIndex = pageIndex;
            //store에 페이징의 페이지 인덱스 이력 저장
            this.$_setPageIndexPagingHists(pageIndex);
          } else {
            //pageIndex = 0 이면 이력에서 삭제플래그로 이력 삭제
            this.$_setPageIndexPagingHists(pageIndex, true);
          }
        }
      }
    },
    /** @description: 로우 클릭시 이벤트 */
    handleRowClick(e) {
      if( this.$listeners['row-click'] ) { //부모 컴포넌트의 이벤트 호출
        this.$emit('row-click', e);
      } else {
        //단일 선택 색상 설정
        setGridSingleSelection(e);

        const rowData = e.data;
        if (rowData) {
          this.dataGridConfig.focusedRowKey = rowData.id;
          this.dataGridConfig.focusedRowData = rowData;
        }
      }
    },
    /** @description: 행이 완전히 접힌 후 발생하는 이벤트 */
    handleRowCollapsed(e) {
      if ( this.$listeners['row-collapsed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-collapsed', e);
      }
    },
    /** @description: 행이 접히기 직전에 발생하는 이벤트 */
    handleRowCollapsing(e) {
      if ( this.$listeners['row-collapsing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-collapsing', e);
      }
    },
    /** @description: 행 더블 클릭 이벤트 */
    handleRowDblClick(e) {
      if ( this.$listeners['row-dbl-click'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-dbl-click', e);
      }
    },
    /** @description: 행이 완전히 펼쳐진 후 발생하는 이벤트 */
    handleRowExpanded(e) {
      if ( this.$listeners['row-expanded'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-expanded', e);
      }
    },
    /** @description: 행이 펼쳐지기 직전에 발생하는 이벤트 */
    handleRowExpanding(e) {
      if ( this.$listeners['row-expanding'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-expanding', e);
      }
    },
    /** @description: 그리드 행이 추가된 이후 이벤트 */
    handleRowInserted(e) {
      if ( this.$listeners['row-inserted'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-inserted', e);
      }
    },
    /** @description: 그리드 행이 추가될 때 이벤트 */
    handleRowInserting(e) {
      if ( this.$listeners['row-inserting'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-inserting', e);
      }
    },
    /** @description: 그리드 행 관련 준비 이벤트 */
    handleRowPrepared(e) {
      if( this.$listeners['row-prepared'] ){ //부모 컴포넌트의 이벤트 호출
        this.$emit('row-prepared', e);
      }
    },
    /** @description: 행이 완전히 삭제된 후에 발생하는 이벤트 */
    handleRowRemoved(e) {
      if ( this.$listeners['row-removed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-removed', e);
      }
    },
    /** @description: 행이 삭제되기 직전에 발생하는 이벤트 */
    handleRowRemoving(e) {
      if ( this.$listeners['row-removing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-removing', e);
      }
    },
    /** @description: 그리드 행이 수정된 후에 발생하는 이벤트 */
    handleRowUpdated(e) {
      if ( this.$listeners['row-updated'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-updated', e);
      }
    },
    /** @description: 그리드 행이 수정되기 직전에 발생하는 이벤트 */
    handleRowUpdating(e) {
      if ( this.$listeners['row-updating'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-updating', e);
      }
    },
    /**
     * @description: 그리드 행 유효성 검사 이벤트
     * @param e
     */
    handleRowValidating(e) {
      if (this.$listeners['row-validating']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-validating', e);
      }
    },
    /** @description: 데이터가 성공적으로 저장된 후 발생하는 이벤트 */
    handleSaved(e) {
      if ( this.$listeners['saved'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('saved', e);
      }
    },
    /** @description: 데이터가 저장되기 직전에 발생하는 이벤트 */
    async handleSaving(e) {
      if ( this.$listeners['saving'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('saving', e);
      } else { //저장 전 데이터 체크할 필요 있으면 checkDataBeforeSaving 사용 ex) 중복 값 있는지 체크 등...
        if ( this.$listeners['check-data-before-saving'] ) { // 부모 컴포넌트의 이벤트 호출
          this.$emit('check-data-before-saving', e, this.saveData);
        } else {
          //저장 전 데이터 체크할 필요 없으면 바로 저장 메서드 호출
          await this.saveData(e);
        }
      }
    },
    /** @description : 데이터 저장 메서드 */
    async saveData(e) {
      e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

      // 변경된 값이 없으면 메시지 출력
      if (e?.changes.length === 0) {
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
      }

      // 변경된 값 존재 여부 체크 && 데이터 그리드 액션명 설정 여부 체크
      if (this.dataGridConfig.apiActionNm.update || this.dataGridConfig.apiActionNm.merge) {
        const isMerge = !!this.dataGridConfig.apiActionNm.merge;
        const gridChanges = this.processGridChanges(e, isMerge, this.dataGridConfig.keyExpr ? this.dataGridConfig.keyExpr : 'id');
        const payload = {
          actionname: isMerge ? this.dataGridConfig.apiActionNm.merge : this.dataGridConfig.apiActionNm.update,
          data: gridChanges,
        };

        const res = await store.dispatch(this.dataGridConfig.callApi, payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          this.refreshData();
        } else {
          e.component.cancelEditData();
        }
      } else {
        this.$log.error('The apiActionNm is not defined.');
        this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }
    },
    /**
     * 그리드 변경된 데이터 준비
     *
     * @param e saving 이벤트 객체
     * @param isMerge 병합 여부 (기본값 true)
     * @param keyExpr key 값 (기본값 'id')
     * @return {*[]}
     */
    processGridChanges(e, isMerge = true, keyExpr = 'id') {
      const data = [];

      e.changes.forEach(d => {
        let dataKey = d.key;
        let dataMap = d.data;

        if (d.type === 'update' && isMerge) {
          e.component.byKey(dataKey).then(data => {
            dataMap = Object.assign(data, d.data);
          }).catch(error => {
            console.error(error);
          });
          dataMap.isAdding = false; // 신규 추가 여부
        } else {
          dataKey = null; // 신규일 경우 key 값이 null 이어야 함
          dataMap.isAdding = true; // 신규 추가 여부 / API에서 신규 추가 여부를 확인할 수 있도록 설정
        }

        dataMap[keyExpr] = dataKey;
        data.push(dataMap);
      });
      return data;
    },
    /**
     * 그리드 변경된 데이터 준비 (작업이력 저장데이터 포함) <br>
     * 해당 함수는 병합 데이터를 기본적으로 만들도록 설정되어 있음
     *
     * @param e saving 이벤트 객체
     * @param originalData 원본 데이터 (기본값 {})
     * @param keyExpr key 값 (기본값 'id')
     * @return {{data: *[], workLog: {content: {}, preContent: {}}}} 변경된 데이터
     */
    processGridChangesWithWorkLog(e, originalData = {}, keyExpr = 'id') {
      const changes = {
        data: [],
        workLog: { content: {}, preContent: {} },
      };

      // https://js.devexpress.com/Vue/Documentation/ApiReference/Common_Types/grids/#DataChangeType 참고
      e.changes.forEach((change, index) => {
        let dataKey = change.key;
        let dataMap = change.data;

        if (change.type === 'update') {
          // 변경 전 데이터 확인
          const oldData = originalData[dataKey];
          dataMap = Object.assign({}, oldData, change.data);

          // 변경 전후의 내용을 기록
          changes.workLog.preContent[index] = oldData;
          changes.workLog.content[index] = dataMap;
        } else if (change.type === 'insert') {
          // 신규 생성 타입
          dataKey = null; // 신규 생성 시 key 값이 없음
          changes.workLog.preContent[index] = {};
          changes.workLog.content[index] = dataMap;
        } else if (change.type === 'remove') {
          // 삭제 타입
          const oldData = originalData[dataKey];
          if (oldData) {
            changes.workLog.preContent[index] = oldData;
            changes.workLog.content[index] = {};
          }
        }

        dataMap[keyExpr] = dataKey;
        changes.data.push(dataMap);
      });

      return changes;
    },
    /** @description: 그리드 선택시 변경 관련 이벤트 */
    handleSelectionChanged(e) {
      if ( this.$listeners['selection-changed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('selection-changed', e);
      } else {
        this.dataGridConfig.selectedRowKeys = e.selectedRowKeys;
        this.dataGridConfig.selectedRowsData = e.selectedRowsData;
      }
    },
    /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
    handleToolbarPreparing(e) {
      if ( this.$listeners['toolbar-preparing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('toolbar-preparing', e);
      } else {
        const toolbarItems = e.toolbarOptions.items;

        // Adds a new item
        if (this.dataGridConfig.showActionButtons?.select) {
          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.LOAD', { defaultValue: '조회' }),
              hint: this.$_msgContents('COMPONENTS.LOAD', { defaultValue: '조회' }),
              showText: 'always',
              elementAttr: { class: 'btn_XS default filled' },
              width: 60,
              height: 30,
              onClick: () => {
                this.refreshData();
              },
            },
            location: 'before',
            sortIndex: 20,
          });
        }

        if (this.dataGridConfig.showActionButtons?.copy) {
          toolbarItems.push({
            location: 'before',
            widget: 'dxButton',
            options: {
              icon: '',
              type: 'normal',
              text: this.$_msgContents('COMPONENTS.COPY', { defaultValue: '복사' }),
              hint: this.$_msgContents('COMPONENTS.COPY', { defaultValue: '복사' }),
              elementAttr: { class: 'btn_XS default filled' },
              width: 60,
              height: 30,
              onClick: () => {
                if (this.dataGridConfig.selectedRowsData?.length) {
                  this.dataGridConfig.selectedRowsData.forEach(element => {
                    this.isClone = true;
                    this.dataGridConfig.selectedCopyData = element;
                    this.$refs[this.dataGridConfig.refName].instance.addRow();
                  });
                } else {
                  this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                }
              },
            },
            sortIndex: 10,
          });
        }

        const setButtonConfig = (item, config) => { // 각 버튼의 기본 설정을 함수로 분리
          item.location = 'before';
          item.options.icon = '';
          item.options.text = config.text;
          item.options.hint = config.text;
          item.showText = 'always';
          item.options.width = '60';
          item.options.height = '30';
          item.options.elementAttr = { class: config.className };
          item.sortIndex = config.sortIndex;
        };

        toolbarItems.forEach(item => { // 추가/저장/취소 버튼을 먼저 숨김 처리
          if (['addRowButton', 'saveButton', 'revertButton'].includes(item.name)) {
            item.options.visible = false;
          }
        });

        // 개별 버튼 설정이 우선
        if (this.dataGridConfig.showActionButtons?.add) { // 추가
          toolbarItems.forEach(item => {
            if (item.name === 'addRowButton') {
              setButtonConfig(item, {
                text: this.$_msgContents('COMPONENTS.ADD', {defaultValue: '추가'}),
                className: 'btn_XS default filled add1',
                sortIndex: 30
              });
              item.options.visible = true;
            }
          });
        }

        if (this.dataGridConfig.showActionButtons?.save) { // 저장
          toolbarItems.forEach(item => {
            if (item.name === 'saveButton') {
              setButtonConfig(item, {
                text: this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'}),
                className: 'btn_XS default filled',
                sortIndex: 40
              });
              item.options.visible = true;
            }
          });
        }

        if (this.dataGridConfig.showActionButtons?.cancel) { // 취소
          toolbarItems.forEach(item => {
            if (item.name === 'revertButton') {
              setButtonConfig(item, {
                text: this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'}),
                className: 'btn_XS white light_filled',
                sortIndex: 50
              });
              item.options.visible = true;
            }
          });
        }

        // update가 true일 경우, 아직 visible이 false인 버튼들을 활성화
        if (this.dataGridConfig.showActionButtons?.update) {
          toolbarItems.forEach(item => {
            if (['addRowButton', 'saveButton', 'revertButton'].includes(item.name) && !item.options.visible) {
              const buttonConfig = {
                'addRowButton': {
                  text: this.$_msgContents('COMPONENTS.ADD', {defaultValue: '추가'}),
                  className: 'btn_XS default filled add1',
                  sortIndex: 30
                },
                'saveButton': {
                  text: this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'}),
                  className: 'btn_XS default filled',
                  sortIndex: 40
                },
                'revertButton': {
                  text: this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'}),
                  className: 'btn_XS white light_filled',
                  sortIndex: 50
                }
              };

              setButtonConfig(item, buttonConfig[item.name]);
              item.options.visible = true;
            }
          });
        }

        if (this.dataGridConfig.showActionButtons?.delete) {
          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
              hint: this.$_msgContents('COMPONENTS.DELETE', { defaultValue: '삭제' }),
              showText: 'always',
              elementAttr: { class: 'btn_XS white light_filled trash' },
              width: 60,
              height: 30,
              onClick: async () => {
                // 선택된 행이 없는 경우
                if (!this.dataGridConfig.selectedRowsData?.length) {
                  this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                  return;
                }

                // 삭제 확인 메시지를 무시한 경우
                if (!(await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', {defaultValue: '선택한 데이터를 삭제하시겠습니까?'})))) {
                  return;
                }

                const payload = {
                  actionname: this.dataGridConfig.apiActionNm.delete,
                  data: this.dataGridConfig.selectedRowsData,
                  loading: true,
                  useErrorPopup: true,
                };

                const res = await store.dispatch(this.dataGridConfig.callApi, payload);
                if (isSuccess(res)) {
                  this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'), { icon: 'success' });
                  this.refreshData();
                }
              },
            },
            location: 'before',
            sortIndex: 60,
          });
        }

        if (this.dataGridConfig.showActionButtons?.customButtons) {
          this.dataGridConfig.showActionButtons.customButtons.forEach((d, i) => {
            if (!d.sortIndex) {
              d.sortIndex = Number('7' + (i + 1));
            }
            toolbarItems.push(d);
          });
        }

        if (this.dataGridConfig.showActionButtons?.excel) {
          toolbarItems.forEach(d => {
            if (d.name === 'exportButton') {
              d.options.icon = '';
              d.options.text = '엑셀';
              d.options.elementAttr = { class: 'excel-btn' };
              d.options.width = 70;
              d.location = 'before';
              d.sortIndex = 100;

              if (this.dataGridConfig.showActionButtons?.csv) {
                e.format = 'csv';
                d.options.items = d.options.items.concat([
                  {
                    icon: 'txtfile',
                    text: `csv ${this.$_msgContents('COMPONENTS.DOWNLOAD', { defaultValue: '다운로드' })}`,
                    onClick: () => {
                      this.handleExportingCheck(e);
                    },
                  },
                ]);
              }
            }
          });
        }

        //toolbar sort
        e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);

        //검색 패널
        if( this.dataGridConfig.searchPanel?.visible ){
          toolbarItems.forEach(item => {
            if (item.name === 'searchPanel') {
              item.locateInMenu = 'never';
              item.location = 'after';
              item.sortIndex = 110;
            }
          });
        }

        //검색 결과
        if (!this.dataGridConfig?.disableTotalCount) {
          toolbarItems.push({
            location: 'after',
            widget: 'dxTemplate',
            template: 'totalCount',
          });
        }

        if (this.dataGridConfig.page.enabled) {
          toolbarItems.push({
            location: 'after',
            widget: 'dxSelectBox',
            options: {
              width: 150,
              height: 30,
              stylingMode: this.stylingMode,
              items: this.dataGridConfig.page.pageList,
              displayExpr: 'label',
              valueExpr: 'value',
              value: this.dataGridConfig.page.pageSize,
              onValueChanged: this.onChangePageSize,
              elementAttr: {
                class: 'page-size-item',
              },
            },
          });
        }
      }
    },
    /**
     * @description: 엑셀 다운로드 사유 입력 모달 이벤트
     */
    onDownloadReason() {
      let event = this.modal.content.sendData;
      const reason = this.$refs.reasonModalRef.reason;
      if (reason.trim() === '') {
        this.$_Msg(
          this.$_msgContents('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
            defaultValue: '다운로드 사유를 입력하세요.',
          }),
        );
        event.cancel = true;
      } else {
        event = this.makeSaveHistory(event, reason);
        event.cancel = false;
        this.onExporting(event);
        this.isOpenModal(false);
      }
    },
    /** @description: 엑셀 다운르도 이력 설정 */
    makeSaveHistory(event, reason = '') {
      const user = {
        userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
        userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
        deptNm: this.$store.getters.getUserInfo?.deptNm || '',
        gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
      };

      const payload = {
        actionname: 'FILE_DOWNLOAD_HISTORY_UPDATE',
        data: [
          {
            ...user,
            reason,
            fileType: 'EXCEL',
            fileNm: this.dataGridConfig.export?.title,
          },
        ],
      };

      event.onSaveHistory = async fileNm => {
        payload.data[0].fileNm = fileNm || this.dataGridConfig.export?.title;
        return await this.CALL_API(payload);
      };
      return event;
    },

    /** @description : 그리드 조회 메서드 */
    async selectDataList() {
      const key = this.dataGridConfig?.keyExpr || 'id';
      if (this.dataGridConfig.apiActionNm && this.dataGridConfig.apiActionNm?.select) {
        this.dataGridConfig.dataSource = new CustomStore({
          key,
          load: async(loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              if (this.dataGridConfig.dataSourceDefaultSortColumn) params.sort = this.dataGridConfig.dataSourceDefaultSortColumn;
              else params.sort = `+${key}`;
            }

            const payload = {
              actionname: this.dataGridConfig.apiActionNm.select,
              data: params,
              loading: this.dataGridConfig.apiActionNm.loading ? this.dataGridConfig.apiActionNm.loading : false,
              useErrorPopup: this.dataGridConfig.apiActionNm.useErrorPopup ? this.dataGridConfig.apiActionNm.useErrorPopup : true,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.$store.dispatch(this.dataGridConfig.callApi, payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;

              // 화면에 표시될 검색결과수 업데이트
              // 삭제를 하면 dxGrid에서 삭제할 id리스트에 대하여 select를 날리기 때문에
              // 실제 조회시 건수가 0이되는 버그가 있어서 예외처리함
              if (params.pagesize) this.dataGridConfig.page.totalCount = rtnData.totalCount;
            } else {
              this.$log.debug('Data Loading Error');
            }
            return rtnData;
          },
          insert: values => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          update: (key, values) => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          remove: key => {
            return new Promise((resolve, reject) => {
              resolve();
            });
          },
          totalCount: loadOptions => {
            return new Promise((resolve, reject) => {
              resolve(0); // 임시로 0 반환
            });
          },
        });
      }
    },
    /** @description : 그리드 refesh 메서드 */
    refreshData() {
      this.$refs[this.dataGridConfig.refName].instance.refresh();
    },
    /** @description : 그리드 repaint 메서드 */
    repaintData() {
      this.$refs[this.dataGridConfig.refName].instance.repaint();
    },
    /**@description : DataGrid clearSelection 메서드 */
    clearSelection() {
      this.$refs[this.dataGridConfig.refName].instance.clearSelection();
    },
    /**@description : DataGrid clearFilter 메서드 */
    clearFilter() {
      this.$refs[this.dataGridConfig.refName].instance.clearFilter();
    },
    /**@description : DataGrid clearSorting 메서드 */
    clearSorting() {
      this.$refs[this.dataGridConfig.refName].instance.clearSorting();
    },
    /** @description: 그리드의 데이터 없을 경우 출력 */
    noDataText() {
      return this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA', { defaultValue: '데이터가 없습니다.' });
    },
    /** @description : 페이징 관련 박스 이동 */
    movePagingBox() {
      const pager = this.$refs[this.dataGridConfig.refName].instance.getView('pagerView').element().dxPager('instance').element();
      const toolbarAfterItem = this.$refs[this.dataGridConfig.refName].instance.getView('headerPanel')._toolbar._$afterSection[0];

      const toolbarItem = document.createElement('div');
      toolbarItem.classList.add('dx-item');
      toolbarItem.classList.add('dx-toolbar-item');
      toolbarItem.classList.add('dx-toolbar-button');
      const toolbarItemContent = document.createElement('div');
      toolbarItemContent.classList.add('dx-item-content');
      toolbarItemContent.classList.add('dx-toolbar-item-content');
      toolbarItemContent.appendChild(pager);
      toolbarItem.appendChild(toolbarItemContent);
      toolbarAfterItem.appendChild(toolbarItem);
    },
    /** @description: 페이징 데이터 바인딩 가져오기 */
    async bindPagingData() {
      this.dataGridConfig.page.pageList = this.$_enums.common.pagingSizeList.values;
      this.dataGridConfig.page.allowedPageSizes = this.dataGridConfig.page.pageList.map(d => d.value);

      //페이지 목록수 캐시 저장 여부와 목록 캐시 여부 체크
      if (this.dataGridConfig.page.pageSizeCacheFl || this.$store.getters.getListCacheFl) {
        await this.$_getPagingHists(this.dataGridConfig);
      }
    },
    /** @description: 페이지 사이즈 변경 이벤트 */
    onChangePageSize(data) {
      if (this.dataGridConfig.page.enabled) this.dataGridConfig.page.pageSize = data.value;
    },
    /**
     * 그리드 Height 계산
     *
     * @param propHeight
     * @return {*|string}
     */
    calculateHeight(propHeight) {
      if (propHeight === null || propHeight === undefined) {
        const height = this.getTopElement('#dxDataGrid') + this.getHeightElement('.dx-datagrid-header-panel');
        return 'calc(100vh - ' + height + 'px)';
      }
      return propHeight;
    },
    /** @description : 상단 위치 정보 */
    getTopElement(e) {
      const divElement = document.querySelector(e);
      const rect = divElement.getBoundingClientRect();
      return rect.top;
    },
    /** @description : 높이 정보 */
    getHeightElement(e) {
      const divElement = document.querySelector(e);
      const computedStyle = window.getComputedStyle(divElement);
      const divHeight = divElement.offsetHeight;
      const marginTop = parseFloat(computedStyle.marginTop);
      const marginBottom = parseFloat(computedStyle.marginBottom);
      return divHeight + marginTop + marginBottom;
    },
  },
  async created() {
    //페이지 목록수 캐시 저장 여부 설정
    const pageSizeCacheFl = this.$_getSystemData('page_size_cache_fl') ? this.$_getSystemData('page_size_cache_fl').configValue : null;
    this.dataGridConfig.page.pageSizeCacheFl = isTrue(pageSizeCacheFl);

    await this.bindPagingData(); //페이징 관련 데이터 바인딩
    if (this.dataGridConfig.apiActionNm && this.dataGridConfig.apiActionNm?.select) {
      await this.selectDataList();
    }
  },
  mounted() {
    this.dataGridConfig.height = this.calculateHeight(this.dataGridConfig.height); // 그리드 높이 설정

    if (this.dataGridConfig.page.enabled) {
      this.movePagingBox();
    }
  },
};
</script>

<style scoped>
/* 엑셀 드롭다운 버튼 */
::v-deep .dx-toolbar-items-container .excel-btn {
  border: 1px solid #1d6b40;
  border-radius: 4px;
  background: #1d6b40;
}

::v-deep .dx-toolbar-items-container .excel-btn .dx-buttongroup-item {
  border: 0;
}

::v-deep .dx-toolbar-items-container .excel-btn .dx-button-content {
  padding: 0 !important;
  line-height: 30px;
}

::v-deep .dx-toolbar-items-container .excel-btn .dx-button-text {
  color: #fff;
}

::v-deep .dx-toolbar-items-container .excel-btn .dx-icon {
  color: #fff;
  vertical-align: middle;
}
/* 엑셀 드롭다운 버튼 */

/* 검색 패널 */
::v-deep .dx-toolbar-items-container .dx-item-content:has(.dx-datagrid-search-panel) {
  padding-bottom: 2px;
}

::v-deep .dx-toolbar-items-container .dx-toolbar-after .dx-datagrid-search-panel {
  margin-right: 10px;
}
/* 검색 패널 */

/* 검색 결과 */
.dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-label .total-count-item {
  font-size: 13px;
  font-weight: normal;
  color: #545454;
}

/* page size selectbox */
.dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-button .page-size-item {
  margin-left: 30px;
}
</style>
